import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React from 'react'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
      textAlign: {
        default: null,
        parseHTML: (element) => element.getAttribute('text-align'),
        renderHTML: (attributes) => {
          return {
            'text-align': attributes.textAlign,
            style: `text-align: ${attributes.textAlign}`,
          }
        },
      },
    }
  },
})

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
  Table,
  TableRow,
  TableHeader,
  CustomTableCell,
]

const content = `
  <div style="padding: 24px">
    <h3 style="text-align: center">Terms of Use for iMile UK Website</h3>
    <p style="text-align: center">Last Update: 08/16/2024</p>
    <p>These Terms of Use (hereinafter as the Terms) apply to you personally and your use of the iMile UK Website at <a href="https://www.imile.com/">https://www.imile.com/</a> (hereinafter as the Website). This website is created and maintained by iMile Logistics Co., Ltd., a company incorporated in the UK whose registered address is C/O MISHCON DE REYA AFRICA HOUSE 70 KINGSWAY ,LONDON ,GREATER LONDON ,UNITED KINGDOM WC2B 6AH and whose registered number is 15652374(hereinafter as iMile).</p>
    <p>By using the Website, you agree to these Terms. Your use of the Website and the functionalities provided to you within the Website shall only be admissible subject to your prior acceptance of the Terms. IF YOU DO NOT AGREE WITH ANY OF THE TERMS, PLEASE DO NOT USE THE WEBSITE.</p>
    <p>iMile reserves the right to change, modify, add or remove portions of these Terms at any time.</p>
    <p>You agree that iMile may, at its sole discretion and without prior notice to you, terminate your access to the Website and/or block your future access to the Website, if iMile determines that you have violated these Terms or other agreements or guidelines which may be associated with your use of the Website.</p>
    <h3>Software Content</h3>
    <p>iMile or its licensors own and retain all rights, including all applicable intellectual property rights, in all software on this Website, in both source and binary forms, sample code, APIs, SDKs, associated documentation, and other related materials (collectively, "Software Content").</p>
    <p>Unless you have obtained a relevant license through other agreement entered between you and iMile, nothing in these Terms grants any right or license to Software Content to you, and you shall not reverse engineer, decompile, disassemble, split, adapt, implant, or implement other derivative works on the Software Content.</p>
    <h3>Trademarks</h3>
    <p>All trademarks and logos displayed, mentioned or otherwise used in this Website are property of iMile or its licensors as stated if applicable. You are not permitted to use any of these trademarks or logos in any way without explicit prior written permission from iMile or such licensors as applicable.</p>
    <h3>Privacy</h3>
    <p>iMile will only use your personal data in accordance with iMile's <a href="https://www.imile.com/privacy-policy-uk">Privacy Policy</a>.</p>
    <h3>Copyright</h3>
    <p>All contents on this Website are copyrighted by iMile or applicable licensors unless otherwise stated. Without prior written consent of iMile or other related parties, any content on the Website shall not be reproduced, modified, promoted, distributed, photocopied, played, disassembled, reverse engineered, decompiled, linked or transmitted with hyperlinks, loaded into other servers in "mirroring method", stored in information retrieval system, or otherwise used for any other purpose by any person by any means.</p>
    <h3>Website Content</h3>
    <p>The Website Contents include but are not limited to text, images, data, web pages, and links offered on or through the Website. This Website and its contents are provided for your convenience only. Although iMile has attempted to provide accurate information on this Website, it assumes no obligation or responsibility whatsoever regarding the accuracy and completeness of the information. The Website Contents may contain inadvertent inaccuracies or typographical errors. These will be corrected at iMile's discretion, as they are found.</p>
    <h3>Links to Third Parties</h3>
    <p>Although links to third party websites may be contained in this Website for your convenience, iMile shall not be responsible for any content of any such websites. You might need to review and agree to applicable rules of use when using such websites. In addition, a link to third-party website does not imply that iMile endorses the site or the products or services referenced therein.</p>
    <h3>Viruses</h3>
    <p>iMile makes all reasonable attempts to exclude viruses from these web pages, but it cannot ensure this exclusion, and no liability is accepted for viruses. Please take all appropriate safeguards before downloading information from these web pages.</p>
    <h3>Disclaimer of Warranties</h3>
    <p>All information provided on this website is provided on an "as is" basis without warranties, guarantees or representations of any kind. iMile hereby expressly disclaims, to the fullest extent permitted by law, all express, implied, statutory or other warranties, guarantees or representations, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement of third-party rights.</p>
    <h3>Limitation of Liability</h3>
    <p>To the extent permitted by law, neither iMile nor any of its affiliates, subsidiaries, directors, agents, employees or other representatives shall be liable for any direct, indirect, special, incidental, consequential, punitive, and/or exemplary damages including without limitation, loss of profits or revenues, loss of data, and/or loss of business, in connection with this Website or the use of or inability to use this Website or reliance on the contents contained herein, even if iMile is advised of the possibility of such damages. Unless otherwise agreed in writing by iMile in any applicable contract, to the extent permitted by the applicable law, iMile shall not be liable for the materials of this Website, regardless of the reason for the liability or the tort theory it is based on.</p>
    <h3>Disclosure of Information</h3>
    <p>All information provided to iMile by visitors to these web pages is considered to be confidential and will not be disclosed by iMile to any third party except as may be required for the provision of the services.</p>
  </div>
`
const PrivacyPolicyBrazil = (props) => {
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box pt={15} display='flex' alignItems='center' justifyContent='center' margin='0 24px'>
        <EditorProvider extensions={extensions} content={content} editable={false}></EditorProvider>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["PrivacyPolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
